// @ts-ignore
import defaultOgImageUrl from '~/assets/jpg/default-og-image.jpg?url';

export async function useDefaultMetatags(story: any, locale: Ref<string>) {
    let metatags = story.value?.content?.metatags;

    if (!metatags) {
        if (!metatags) {
            const defaultMetatagsStory = await useAsyncStoryblok('home', {
                version: useRoute().query._storyblok ? 'draft' : 'published',
                language: locale.value,
            });
            metatags = defaultMetatagsStory.value?.content?.metatags;
        }
    }

    const defaultTitle = 'Tiptapp';
    const defaultDescription = getDefaultDescription(locale.value);

    const meta = {
        title: metatags?.title || defaultTitle,
        ogTitle: metatags?.og_title || metatags?.title || defaultTitle,
        twitterTitle: metatags?.twitter_title || metatags?.title || defaultTitle,
        description: metatags?.description || defaultDescription,
        ogDescription: metatags?.og_description || metatags?.description || defaultDescription,
        twitterDescription:
            metatags?.twitter_description || metatags?.description || defaultDescription,
        ogImage: metatags?.og_image || defaultOgImageUrl,
        twitterImage: metatags?.twitter_image || metatags?.og_image || defaultOgImageUrl,
    };

    return { meta };
}

function getDefaultDescription(locale: string) {
    switch (locale) {
        case 'sv':
            return 'Tiptapp är som en taxi för prylar. Oavsett om du behöver hjälp med att flytta, leverera, handla eller återvinna kopplar vår app omedelbart ihop dig med tusentals helpers - vanliga människor med en bil och tid att hjälpa dig.';
        case 'de':
            return 'Ganz gleich, ob du Hilfe beim Umzug, bei der Lieferung, beim Einkaufen oder bei der Entsorgung benötigst, unsere App bringt dich sofort mit Tausenden von Helpern zusammen - ganz normale Menschen mit einem Auto und Zeit, um dir zu helfen.';
        case 'pt':
            return 'A Tiptapp é a boleia perfeita para as tuas coisas. Consegue recolhas rápidas, entregas das tuas compras OLX e alguém que recicle o que já não precisas. Fácil e conveniente!';
        default:
            return 'Whether you need help moving, delivering, shopping or recycling, our app instantly connects you with thousands of helpers - everyday people with a car and the time to help you out.';
    }
}
